.card {
  position: relative;
  // display: flex;
  // flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border-radius: 3px;
  transition: all 0.4s ease-in-out;
  &--disabled {
    background-color: $gray-100;
    pointer-events: none;
    &::after {
      content: "";
      height: 100%;
      width: 100%;
      top: 0;
      right: 0;
      position: absolute;
      background-color: $gray-100;
      opacity: 0.4;
    }
    .form-check {
      opacity: 1;
      pointer-events: visible;
      z-index: 1;
    }
  }
  &--shadow-5 {
    box-shadow: $shadow-5;
  }
  &--shadow-3 {
    box-shadow: $shadow-3;
  }
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.57rem;
}

.card-header {
  padding: 0.75rem 1.57rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 0 solid transparent;
}

.card-header:first-child {
  border-radius: calc(0 - 0) calc(0 - 0) 0 0;
}

.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 20px 30px;
  background-color: $white;
  box-shadow: 0px -2px 0px #dbe2eb;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.card-footer:last-child {
  border-radius: 0 0 calc(0 - 0) calc(0 - 0);
}

.card-header-tabs {
  margin-right: -0.785rem;
  margin-bottom: -0.75rem;
  margin-left: -0.785rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.785rem;
  margin-left: -0.785rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img {
  width: 100%;
  border-radius: calc(0 - 0);
}

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0 - 0);
  border-top-right-radius: calc(0 - 0);
}

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0 - 0);
  border-bottom-left-radius: calc(0 - 0);
}

.card-deck {
  display: flex;
  flex-direction: column;
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }

  .card-deck .card {
    display: flex;
    flex: 1 0 0;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group {
  display: flex;
  flex-direction: column;
}

.card-group > .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    flex-flow: row wrap;
  }

  .card-group > .card {
    flex: 1 0 0;
    margin-bottom: 0;
  }

  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }

  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }

  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion > .card {
  overflow: hidden;
}

.ncard {
  padding: 10px;
  background-color: $white;
  border-radius: 3px;
  height: 100%;
  max-height: 120px;
}
.hasactive .ncard {
  opacity: 0.7;
  cursor: pointer;

  &.active {
    box-shadow: $shadow-4;
    opacity: 1;
  }
}
.sbox {
  padding: 5px;
  height: 30px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  border-radius: 4px;
  &--wrapper {
    &:hover .sbox {
      background-color: $brand-500;
      color: $white;
    }  
  }
  &:hover {
    background-color: $brand-500;
    color: $white;
  }
  &--default {
    color: $gray-700;
    background-color: $gray-50;
  }
  &--active {
    color: $white;
    background-color: $brand-500;
  }
}
.bbox {
  padding: 5px 7px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 60px;
  transition: 0.4s ease all;
  &:hover {
    background-color: $brand-500;
    color: $white;
    .bbox__title,
    .bbox__number {
      color: $white;
    }
  }
  &__title {
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    &--lg {
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
    }
  }
  &__number {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }
  &--default {
    border: 1px solid $gray-100;
    border-radius: 3px;
    .bbox__title {
      color: $gray-400;
      &--lg {
        color: $textColor;
      }
    }
    .bbox__number {
      color: $gray-900;
    }
  }
  &--active {
    background-color: $brand-500;
    color: $white;
    .bbox__title,
    .bbox__number {
      color: $white;
    }
  }
  &--tborder {
    border-top: 1px solid $gray-100;
    padding: 0;
    .bbox__number {
      font-size: 40px;
      line-height: 48px;
    }
  }
}

.filter-row {
  background-color: $white;
  border-radius: 6px;
  padding: 10px 13px;
}
.card-blue {
  &__avatar {
    background-color: #8caeef !important;
  }
}
.card-pink {
  &__avatar {
    background-color: #ce93d8 !important;
  }
}
.outline-card {
  border: 1px solid $gray-100;
  border-radius: 5px;
  position: relative;
  &__notes {
    background-color: #fff8e6;
    padding: 5px 8px;
    &--icon {
      color: #b0954e;
    }
  }
  &--blue {
    &::before {
      content: "";
      position: absolute;
      width: 3px;
      left: -1px;
      height: 100%;
      top: 0;
      background-color: #8caeef;
      border-radius: 3px 0 0 3px;
    }
  }
  &--pink {
    &::before {
      content: "";
      position: absolute;
      width: 3px;
      left: -1px;
      height: 100%;
      top: 0;
      background-color: #ce93d8;
      border-radius: 3px 0 0 3px;
    }
  }
}
