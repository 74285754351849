.verify-otp {
  height: 100vh !important;
}

.otpContainer {
  margin: 5% auto;
}

.otpInput {
  width: 5rem !important;
  height: 5rem;
  margin: 0 1rem;
  font-size: 2rem;
  text-align: center;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}
