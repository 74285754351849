.menu-accordion {
  height: calc(100vh - 186px);
  overflow-y: auto;
}
.accordion-wrapper {
  & + * {
    margin-top: 0.5em;
  }
}

.accordion-item {
  overflow: hidden;
  transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
  height: auto;
  max-height: 9999px;
}

.accordion-item.collapsed {
  max-height: 0;
  transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
}

.accordion-title {
  font-weight: 400;
  cursor: pointer;
  color: black;
  padding: 10px 15px;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover,
  &.open {
    background-color: #367bf6;
    color: white;
  }

  &.open {
    &::after {
      content: "";
      border-top: 0;
      border-bottom: 5px solid;
    }
  }
}

.accordion-content {
  background-color: #dbe2eb;
}

.accordation-child {
  cursor: pointer;
  &__text {
  }
  .accordation-child__text {
    padding: 7px 25px;
  }
}

.xx {
  position: relative;
}
p {
  font-size: 18px;
}
#section1 {
  padding-top: 50px;
  height: 500px;
  color: white;
  background-color: green;
}
#section2 {
  padding-top: 50px;
  height: 500px;
  color: green;
  background-color: white;
}
#section3 {
  padding-top: 50px;
  height: 500px;
  color: white;
  background-color: green;
}
#section4 {
  padding-top: 50px;
  height: 500px;
  color: green;
  background-color: white;
}

.navbar-fixed-top {
  position: fixed;
}