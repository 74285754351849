.btn {
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  color: $textColor;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 20px;
  border-radius: $buttonBorderRadius;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  svg {
    height: 20px;
  }
  &:hover {
    text-decoration: none;
  }

  &:focus,
  &.focus {
    outline: 0;
  }
  &.disabled,
  &:disabled {
    opacity: 0.65;
    box-shadow: none;
    cursor: not-allowed;
  }
 
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: $buttonText;
  background-color: $primary;
  border-color: $primary;
  &:hover {
    background-color: var(--color-primary-400);
    border-color: var(--color-primary-400);
  }

  &:focus,
  &.focus {
    background-color: var(--color-primary-400);
    border-color: var(--color-primary-400);
  }

  &.disabled,
  &:disabled {
    color: $buttonText;
    background-color: var(--color-primary-200);
    border-color: var(--color-primary-200);
  }
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    background-color: var(--color-primary-300);
    border-color: var(--color-primary-300);
  }
}

.btn-primary-dark {
  color: #fff;
  background-color: $brand-700;
  border-color: $brand-700;

  &:active,
  &:hover,
  &:focus {
    background-color: $brand-800;
    border-color: $brand-800;
  }
}

.btn-secondary {
  color: #fff;
  background-color: $gray-500;
  border-color: $gray-500;
  &:hover {
    background-color: $gray-400;
    border-color: $gray-400;
  }
  &:focus,
  &.focus {
    background-color: $gray-600;
    border-color: $gray-600;
  }

  &.disabled,
  &:disabled {
    color: $gray-400;
    background-color: $gray-50;
    border-color: $gray-50;
  }
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    background-color: $gray-600;
    border-color: $gray-600;
  }
}

.btn-success {
  color: #fff;
  background-color: $accent-500;
  border-color: $accent-500;
  &:hover {
    background: $accent-700;
    border-color: $accent-700;
  }

  &:focus,
  &.focus,
  &:active,
  &.active {
    background-color: $accent-700;
    border-color: $accent-700;
  }

  &.disabled,
  &:disabled {
    color: $gray-400;
    background-color: $accent-50;
    border-color: $accent-50;
  }
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    background-color: $accent-600;
    border-color: $accent-600;
  }
}

.btn-info {
  color: $white;
  background-color: $badgeDarkBlue;
  border-color: $badgeDarkBlue;

  &:hover {
    color: #fff;
    background-color: lighten($badgeDarkBlue, 10%);
    border-color: lighten($badgeDarkBlue, 10%);
  }

  &:focus,
  &.focus {
    background-color: darken($badgeDarkBlue, 10%);
    border-color: darken($badgeDarkBlue, 10%);
  }

  &.disabled,
  &:disabled {
    color: $gray-400;
    background-color: $gray-50;
    border-color: $gray-50;
  }
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    background-color: darken($badgeDarkBlue, 10%);
    border-color: darken($badgeDarkBlue, 10%);
  }
}

.btn-warning {
  color: $white;
  background-color: $warning-500;
  border-color: $warning-500;
  &:hover {
    background-color: $warning-400;
    border-color: $warning-400;
  }

  &:focus,
  &.focus {
    background-color: $warning-600;
    border-color: $warning-600;
  }

  &.disabled,
  &:disabled {
    color: $gray-400;
    background-color: $gray-50;
    border-color: $gray-50;
  }
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    background-color: $warning-600;
    border-color: $warning-600;
  }
}

.btn-danger {
  color: $white;
  background-color: $error-500;
  border-color: $error-500;
  &:hover {
    background-color: $error-400;
    border-color: $error-400;
  }

  &:focus,
  &.focus {
    background-color: $error-500;
    border-color: $error-500;
  }

  &.disabled,
  &:disabled {
    color: $gray-400;
    background-color: $error-50;
    border-color: $error-50;
  }
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    background-color: $error-600;
    border-color: $error-600;
  }
}

.btn-white {
  color: $gray-900;
  background-color: $white;
  border-color: $white;
  box-shadow: $shadow-1;
  &:hover {
    background-color: $gray-25;
    border-color: $gray-25;
  }

  &:focus,
  &.focus {
    background-color: $gray-50;
    border-color: $gray-50;
  }

  &.disabled,
  &:disabled {
    color: $gray-400;
    background-color: $gray-50;
    border-color: $gray-50;
  }
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    background-color: $gray-50;
    border-color: $gray-50;
  }
}

.btn-light {
  color: $gray-900;
  background-color: $gray-50;
  border-color: $gray-50;
  &:hover {
    background-color: $gray-25;
    border-color: $gray-25;
  }

  &:focus,
  &.focus {
    background-color: $gray-100;
    border-color: $gray-100;
  }

  &.disabled,
  &:disabled {
    color: $gray-400;
    background-color: $gray-50;
    border-color: $gray-50;
  }
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    background-color: $gray-100;
    border-color: $gray-100;
  }
}

.btn-close {
  color: $gray-500;
  border-color: none;
  &:hover {
    color: $gray-400;
  }

  &:focus,
  &.focus {
    color: $gray-600;
  }

  &.disabled,
  &:disabled {
    color: $gray-400;
    background-color: $gray-50;
    border-color: $gray-50;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    color: $gray-50;
  }
}

.btn-dark {
  color: $white;
  background-color: $gray-800;
  border-color: $gray-800;
  &:hover {
    background-color: $gray-700;
    border-color: $gray-700;
  }

  &:focus,
  &.focus {
    background-color: $gray-900;
    border-color: $gray-900;
  }

  &.disabled,
  &:disabled {
    color: $gray-400;
    background-color: $gray-50;
    border-color: $gray-50;
  }
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    background-color: $gray-900;
    border-color: $gray-900;
  }
}

.btn-cyan {
  color: #fff;
  background-color: $cyan;
  border-color: $cyan;
  &:hover {
    background-color: lighten($cyan, 10%);
    border-color: lighten($cyan, 10%);
  }

  &:focus,
  &.focus {
    background-color: darken($cyan, 10%);
    border-color: darken($cyan, 10%);
  }

  &.disabled,
  &:disabled {
    color: $gray-400;
    background-color: $gray-50;
    border-color: $gray-50;
  }
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    background-color: darken($cyan, 10%);
    border-color: darken($cyan, 10%);
  }
}

.btn-orange {
  color: $white;
  background-color: $badgeYellow;
  border-color: $badgeYellow;
  &:hover {
    background-color: lighten($badgeYellow, 10%);
    border-color: lighten($badgeYellow, 10%);
  }

  &:focus,
  &.focus {
    background-color: darken($badgeYellow, 10%);
    background-color: darken($badgeYellow, 10%);
  }

  &.disabled,
  &:disabled {
    color: $gray-400;
    background-color: $gray-50;
    border-color: $gray-50;
  }
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    background-color: darken($badgeYellow, 10%);
    background-color: darken($badgeYellow, 10%);
  }
}

.btn-purple {
  color: $white;
  background-color: $badgePurple;
  border-color: $badgePurple;
  &:hover {
    background-color: lighten($badgePurple, 10%);
    border-color: lighten($badgePurple, 10%);
  }

  &:focus,
  &.focus {
    background-color: darken($badgePurple, 10%);
    border-color: darken($badgePurple, 10%);
  }

  &.disabled,
  &:disabled {
    color: $gray-400;
    background-color: $gray-50;
    border-color: $gray-50;
  }
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    background-color: darken($badgePurple, 10%);
    border-color: darken($badgePurple, 10%);
  }
}

.btn-purple-500 {
  color: $white;
  background-color: $badgeDarkPurple;
  border-color: $badgeDarkPurple;

  &:hover {
    background-color: lighten($badgeDarkPurple, 10%);
    border-color: lighten($badgeDarkPurple, 10%);
  }

  &:focus,
  &.focus {
    background-color: darken($badgeDarkPurple, 10%);
    border-color: darken($badgeDarkPurple, 10%);
  }

  &.disabled,
  &:disabled {
    color: $gray-400;
    background-color: $gray-50;
    border-color: $gray-50;
  }
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    background-color: darken($badgeDarkPurple, 10%);
    border-color: darken($badgeDarkPurple, 10%);
  }
}

.btn-gray-100 {
  color: $textColor;
  background-color: $gray-100;
  border-color: $gray-100;
  &:hover {
    background-color: $gray-300;
    border-color: $gray-300;
  }

  &:focus,
  &.focus {
    background-color: $gray-300;
    border-color: $gray-300;
  }

  &.disabled,
  &:disabled {
    color: $gray-400;
    background-color: $brand-50;
    border-color: $brand-50;
  }
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    background-color: $brand-600;
    border-color: $brand-600;
  }
}

//Outline Buttons
.btn-outline-primary {
  color: $gray-900;
  border-color: $primary;
  &:hover {
    background-color: $brand-50;
  }

  &:focus,
  &.focus {
    background-color: $brand-50;
    color: $primary;
  }

  &.disabled,
  &:disabled {
    color: $gray-400;
    background-color: $white;
    border-color: $gray-300;
  }
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    background-color: $brand-50;
    color: $primary;
  }
}

.btn-outline-secondary {
  background-color: $white;
  color: $gray-900;
  border-color: $gray-500;

  &:hover {
    background-color: $gray-50;
  }

  &:focus,
  &.focus {
    background-color: $gray-50;
    color: $gray-500;
  }

  &.disabled,
  &:disabled {
    color: $gray-400;
    background-color: $white;
    border-color: $gray-300;
  }
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    background-color: $gray-50;
    color: $gray-500;
  }
}

.btn-outline-success {
  background-color: $white;
  color: $accent-500;
  border-color: $accent-500;
  &:hover {
    background-color: $accent-50;
  }

  &:focus,
  &.focus {
    background-color: $accent-50;
    color: $accent-500;
  }

  &.disabled,
  &:disabled {
    color: $gray-400;
    background-color: $white;
    border-color: $gray-300;
  }
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    background-color: $accent-50;
    color: $accent-500;
  }
}

.btn-outline-info {
  background-color: $white;
  color: $gray-900;
  border-color: $badgeDarkBlue;
  &:hover {
    background-color: lighten($badgeDarkBlue, 50%);
  }

  &:focus,
  &.focus {
    background-color: lighten($badgeDarkBlue, 50%);
    color: $badgeDarkBlue;
  }

  &.disabled,
  &:disabled {
    color: $gray-400;
    background-color: $white;
    border-color: $gray-300;
  }
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    background-color: lighten($badgeDarkBlue, 50%);
    color: $badgeDarkBlue;
  }
}

.btn-outline-warning {
  background-color: $white;
  color: $gray-900;
  border-color: $warning-500;
  &:hover {
    background-color: $warning-50;
  }

  &:focus,
  &.focus {
    background-color: $warning-50;
    color: $warning-500;
  }

  &.disabled,
  &:disabled {
    color: $gray-400;
    background-color: $white;
    border-color: $gray-300;
  }
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    background-color: $warning-50;
    color: $warning-500;
  }
}

.btn-outline-danger {
  background-color: $white;
  color: $gray-900;
  border-color: $error-500;
  &:hover {
    background-color: $error-50;
  }

  &:focus,
  &.focus {
    background-color: $error-50;
    color: $error-500;
  }

  &.disabled,
  &:disabled {
    color: $gray-400;
    background-color: $white;
    border-color: $gray-300;
  }
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    background-color: $error-50;
    color: $error-500;
  }
}

.btn-outline-light {
  color: $textColor;
  border-color: $light;
  &:hover,
  &:focus {
    border-color: $gray-500;
  }
  &:disabled,
  &.disabled {
    color: $gray-400;
    background-color: $white;
    border-color: $gray-300;
  }
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    border-color: $gray-500;
    background-color: $gray-25;
    color: $gray-900;
  }
}

.btn-outline-dark {
  background-color: $white;
  color: $gray-900;
  border-color: $gray-800;
  &:hover {
    background-color: $gray-50;
  }

  &:focus,
  &.focus {
    background-color: $gray-50;
    color: $gray-900;
  }

  &.disabled,
  &:disabled {
    color: $gray-400;
    background-color: $white;
    border-color: $gray-300;
  }
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    background-color: $gray-50;
    color: $gray-900;
  }
}

.btn-outline-cyan {
  background-color: $white;
  color: $gray-900;
  border-color: $cyan;

  &:hover {
    background-color: lighten($cyan, 40%);
  }

  &:focus,
  &.focus {
    background-color: lighten($cyan, 40%);
    color: $cyan;
  }

  &.disabled,
  &:disabled {
    color: $gray-400;
    background-color: $white;
    border-color: $gray-300;
  }
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    background-color: lighten($cyan, 40%);
    color: $cyan;
  }
}

.btn-outline-orange {
  background-color: $white;
  color: $gray-900;
  border-color: $badgeYellow;
  &:hover {
    background-color: lighten($badgeYellow, 40%);
  }

  &:focus,
  &.focus {
    background-color: lighten($badgeYellow, 40%);
    color: $badgeYellow;
  }

  &.disabled,
  &:disabled {
    color: $gray-400;
    background-color: $white;
    border-color: $gray-300;
  }
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    background-color: lighten($badgeYellow, 40%);
    color: $badgeYellow;
  }
}

.btn-outline-purple {
  background-color: $white;
  color: $gray-900;
  border-color: $badgePurple;
  &:hover {
    background-color: lighten($badgePurple, 40%);
  }

  &:focus,
  &.focus {
    background-color: lighten($badgePurple, 40%);
    color: $badgePurple;
  }

  &.disabled,
  &:disabled {
    color: $gray-400;
    background-color: $white;
    border-color: $gray-300;
  }
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    background-color: lighten($badgePurple, 40%);
    color: $badgePurple;
  }
}

.btn-link {
  color: $gray-500;
  &:hover {
    color: $gray-600;
  }

  &:focus,
  &.focus {
    color: $gray-600;
  }

  &:disabled,
  &.disabled {
    color: $gray-400;
    background-color: $white;
    border-color: none;
  }
}

.btn-lg,
.btn-group-lg > .btn {
  padding: 9px 15px;
  font-size: 12px;
  line-height: 20px;
  border-radius: $buttonBorderRadius;
  svg {
    height: 16px;
    width: 16px;
  }
}

.btn-sm,
.btn-group-sm > .btn {
  padding: 2px 8px;
  font-size: 12px;
  line-height: 20px;
}

.btn-sm {
  svg {
    height: 16px;
  }
}
.btn-xs {
  svg {
    height: 14px;
  }
}

.btn-xs,
.btn-group-xs > .btn {
  padding: 4px;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

//Buttons Group

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}

.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.dropdown-toggle-split:after,
.dropup .dropdown-toggle-split:after,
.dropright .dropdown-toggle-split:after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split:before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split,
.btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split,
.btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group.show .dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.btn-group.show .dropdown-toggle.btn-link {
  box-shadow: none;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type="radio"],
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

//Bottoms

.btn-circle {
  border-radius: 100%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-circle.btn-sm,
.btn-group-sm > .btn-circle.btn {
  width: 30px;
  height: 30px;
  font-size: 14px;
}
.btn-circle.btn-xs,
.btn-group-xs > .btn-circle.btn {
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  padding: 0;
}

.btn-circle.btn-lg,
.btn-group-lg > .btn-circle.btn {
  width: 50px;
  height: 50px;
  padding: 14px 15px;
  font-size: 18px;
  line-height: 23px;
}

.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 14px 15px;
  font-size: 24px;
}

.btn-rounded {
  border-radius: 60px;
}

.btn-rounded.btn-lg,
.btn-group-lg > .btn-rounded.btn {
  padding: 0.75rem 1.5rem;
}

.btn-rounded.btn-sm,
.btn-group-sm > .btn-rounded.btn {
  padding: 0.25rem 0.5rem;
  font-size: 12px;
}

.btn-rounded.btn-md {
  padding: 12px 35px;
  font-size: 16px;
}

.btn-group .btn,
.input-group .btn {
  margin-right: 0;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -o-transform: translateY(0);
  box-shadow: none;
}

.button-group .btn {
  margin-bottom: 5px;
}

.no-button-group .btn {
  margin-bottom: 5px;
  margin-right: 0;
}

//error Buttons
.btn-outline-error-100 {
  border-color: $error-100;
  color: $error-500;
  background-color: transparent;
  &:hover,
  &:focus {
    background-color: $error-100;
  }
}
