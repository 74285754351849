.rdg {
    border: 0;
    -webkit-overflow-scrolling: auto;
  }

  .fill-grid {
    /* height: 80vh; */
    height: calc(100vh - 387px);
    background-color: #f1f4f8 !important;
  }
  .fill-grid.atyard-grid {
    height: calc(100vh - 330px);
  }
  .fill-grid.customer-grid {
    height: calc(100vh - 300px);
  }
  .fill-grid-full {
    /* height: 80vh; */
    height: calc(100vh - 200px);
    background-color: #f1f4f8 !important;
  }
  .white {
    color: white;
  }

  .fill-grid .rdg-row, .fill-grid-full .rdg-row {
    background-color: white;
    color: black;
  }

  .rdg-cell {
    border: 1px solid #f1f4f8;
  }

  .bg_noneditable {
    background-color: #fafafa;
  }

  /* .rdg-cell {
      overflow: visible !important;
    } */

  .rdg-cell:first-child[aria-selected="true"] {
    outline: none !important;
  }
  .customer-grid .rdg-cell[aria-selected="true"] {
    outline: none !important;
  }
  .atyard-grid .rdg-cell[aria-selected="true"] {
    outline: none !important;
  }
  .load-pricing .rdg-cell[aria-selected="true"] {
    outline: none !important;
  }
  .driver-pay .rdg-cell[aria-selected="true"] {
    outline: none !important;
  }

  .rdg-cell[aria-selected="true"] {
    outline: 2px solid #367bf6;
  }
  .cell[aria-selected="true"] {
    outline: 2px solid #367bf6 !important;
  }

  .cell-select-none[aria-selected="true"] {
    outline: none !important;
  }

  div[role="columnheader"] {
    background-color: #f1f4f8;
    color: black;
    border: none;
    padding-inline: 8px;
  }

  .rdg-text-editor {
    appearance: none;
    box-sizing: border-box;
    inline-size: 100%;
    block-size: 100%;
    padding-block: 0;
    padding-inline: 6px;
    border: 2px solid #ccc;
    vertical-align: top;
    color: black;
    background-color: white;
    font-family: inherit;
    font-size: var(--rdg-font-size);
  }

  .rdg-text-editor:focus {
    border-color: var(--rdg-selection-color);
    outline: none;
  }

  .rdg-text-editor::placeholder {
    color: #999;
    opacity: 1;
  }

  .border-left {
    border-left: 2px solid red;
  }

  .border {
    border: 3px solid palevioletred;
  }

  .d-flex {
    display: flex;
  }

  .item-d-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .date_field {
    width: 100%;
    display: flex;
    min-height: 50px;
  }

  .pl-0 {
    padding-left: 0 !important;
  }

  .p_5 {
    padding: 5px !important;
  }

  .d-flex {
    display: flex;
  }

  .align-items {
    align-items: center;
  }
  .dispatch-icon {
    color: #dbe2eb;
    width: 16px;
    height: 16px;
  }

  .text-warning-500 {
    color: #f19c38 !important;
  }

  .text-gray-700 {
    color: #394e66 !important;
  }

  .text-badgeblue {
    color: #367bf6 !important;
  }

  .text-error-500 {
    color: #eb4e3d !important;
  }

  .text-gray-700 {
    color: #394e66 !important;
  }

  .ref_number_cell {
    display: flex;
    flex-direction: column;
  }

  .mt-4 {
    margin-top: 4px;
  }

  .flex-column {
    flex-direction: column !important;
  }

  .mbsc-windows.mbsc-textfield-wrapper {
    margin: 0 !important;
  }

  /* .rdg-cell {
      padding: 0 !important;
    } */

  .mbsc-windows.mbsc-textfield {
    height: inherit !important;
  }

  .mbsc-windows.mbsc-textfield-box {
    border-color: inherit !important;
  }

  .rdg-cell {
    display: flex !important;
    align-items: center !important;
    padding: 0;
  }

  .fill-grid .rdg-row {
    height: inherit !important;
  }

  .text-dark {
    color: #172a41 !important;
  }

  .font-12 {
    font-size: 12px !important;
  }

  .align-items-start {
    align-items: flex-start !important;
  }

  .fill-grid .rdg-checkbox, .fill-grid-full .rdg-checkbox {
    border: 1px solid #687d96;
    border-radius: 3px;
    width: 16px;
    height: 16px;
  }
  .fill-grid-driverItineray {
    /* height: 80vh; */
    height: calc(100vh - 199px);
    background-color: #f1f4f8 !important;
  }
  /* .fill-grid .rdg-row .c1wupbe700-beta13[aria-selected=true] {
     outline: none !important;
    } */

  .fill-grid .rdg-row .c1noyk41700-beta13:checked + .cdwjxv8700-beta13, .fill-grid-full .rdg-row .c1noyk41700-beta13:checked + .cdwjxv8700-beta13 {
    background-color: var(--color-primary-500);
    outline: 3px solid white;
  }

  .fill-grid .rdg-row:hover, .fill-grid-full .rdg-row:hover {
    background-color: #f6f8ff;
  }

  .fill-grid .rdg-cell:first-child, .fill-grid-full.rdg-cell:first-child {
    position: sticky !important;
  }



  .fill-grid .rdg-row:hover .rdg-cell:first-child, .fill-grid-full .rdg-row:hover .rdg-cell:first-child {
    position: relative;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background-image: -webkit-linear-gradient(
      left,
      var(--color-primary-500) 2px,
      transparent 0
    );
    background-image: -o-linear-gradient(
      left,
      var(--color-primary-500) 2px,
      transparent 2px
    );
    background-image: linear-gradient(
      90deg,
      var(--color-primary-500) 2px,
      transparent 0
    );
  }

  .fill-grid .cell--wrapper, .fill-grid-full .cell--wrapper {
    height: 100% !important;
    display: flex;
    align-items: center;
    justify-content: start;
    width: 100%;
    padding: 5px;
  }

  .fill-grid .rdg-header-row, .fill-grid-full .rdg-header-row {
    border: none;
    padding: 7.5px 10px;
    background-color: #f1f4f8;
  }

  .fill-grid .rdg-header-row .rdg-header-sort-name, .fill-grid-full .rdg-header-row .rdg-header-sort-name {
    font-size: 11px;
    color: #687d96 !important;
    font-weight: 600 !important;
    padding: 5px;
  }

  .fill-grid .tooltip, .fill-grid-full .tooltip  {
    padding: 0 8px !important;
    line-height: 30px !important;
  }

  /* .fill-grid .tooltip[data-placement^="top-center"] {
      transform: translate(-50%, 40%);
    } */

  /* .fill-grid .cell[aria-selected="true"] {
      outline: 1px solid #e9e9e9 !important;
    } */

  .fill-grid .rdg-row .rdg-cell:first-child, .fill-grid-full .rdg-row .rdg-cell:first-child {
    position: relative;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .fill-grid .load-info, .fill-grid-full .load-info {
    line-height: 22px;
    padding-left: 10px;
  }

  .fill-grid .customer-info, .fill-grid-full .customer-info {
    line-height: 22px;
  }

  .fill-grid .border--sm-height, .fill-grid-full .border--sm-height {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .fill-grid .border--sm-height .inner-content, .fill-grid-full .border--sm-height .inner-content  {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: center;
  }

  .fill-grid .cell-content, .fill-grid-full .cell-content {
    width: 100% !important;
    display: block !important;
    font-size: 12px;
    line-height: 22px;
    display: flex;
    align-items: center;
    padding: 5px !important;
  }

  .lh-16 {
    line-height: 16px !important;
  }

  .fill-grid .cell-content.ref_number_cell, .fill-grid-full .cell-content.ref_number_cell {
    align-items: flex-start;
    padding-left: 0;
  }

  .driver_list li {
    padding: 6px;
    border-radius: 3px;
  }
  .driver_list li:hover {
    background-color: #f1f4f8;
  }
  .rdg-cell:first-child {
    position: sticky !important;
    justify-content: center;
  }

  .fill-grid .rdg-cell .rdg-text-editor, .fill-grid-full .rdg-cell .rdg-text-editor {
    border-radius: 3px;
    border: 1px solid #bfccda;
  }

  .rdg-cell.rdg-editor-container {
    padding: 5px !important;
  }

  .rdg-header-row .rdg-cell[aria-selected="true"] {
    outline: none;
  }
  .rdg-header-row .rdg-cell {
    width: 100%;
    display: block !important;
  }

  .rdg-header-row .rdg-cell .rdg-sort-arrow {
    fill: #b3b3b3;
    margin-bottom: 6px;
  }

  .rdg-header-row .rdg-cell .rdg-header-sort-name {
    line-height: 14px;
  }

  .task-list-settings__menu {
    position: absolute;
    right: 0;
    max-width: 355px;
    width: 355px;
  }

  .task-list-settings__menu ul li:hover {
    background-color: #f0f1f3;
  }

  .task-list-settings__menu .app-search {
    border-top: 1px solid #e9ebf0;
    border-bottom: 1px solid #e9ebf0;
  }

  .load_tooltip .tooltip {
    left: 70px;
    top: 24px;
  }


  .transition-white-field{
    transition: background-color .4s ease-out;
    background-color: $white;
  }

  .cancel-margin {
    margin:"0px 8px";
  }

  // Recommendation Field
  .recommendation-field-wrapper {
    padding: 6px 0px; 
    gap: 3px;

    .recommendation-field {
      background: $brand-50;
      border: 1px solid $brand-100;
      border-radius: 3px;
      padding: 6px 10px;
      height: 100%;
      min-width: 150px;
      // height: 36px;

      &:hover , &.active{
        cursor: pointer;
        background-color: $brand-100!important;
        border: 1px solid $brand-500!important;
      }

      &.disabled {
        opacity: 0.4;
        color: $gray-900 ;
      }


      &.warning {
        background: $warning-900;
        border: 1px solid $brand-500;
        color: #fff;
      }

      .form-check {
        height: 100%;

        label {
          font-size: 12px;
        }
      }
    }
  }

  .table-fixed {
    table-layout: fixed;
  }